import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaHome, FaShoppingBag } from 'react-icons/fa'; // Import icons
import './Profile.css';

function Profile() {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [items, setItems] = useState([]);
  const [rentals, setRentals] = useState({ pending: [], confirmed: [], completed: [], rejected: [] });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProfile() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        setUser(response.data.user);
        setItems(response.data.items);
        
        if (response.data.rentals) {
          setRentals({
            pending: response.data.rentals.filter(rental => rental.status === 'pending'),
            confirmed: response.data.rentals.filter(rental => rental.status === 'confirmed'),
            completed: response.data.rentals.filter(rental => rental.status === 'completed'),
            rejected: response.data.rentals.filter(rental => rental.status === 'rejected')
          });
        } else {
          setRentals({ pending: [], confirmed: [], completed: [], rejected: [] });
        }
        
      } catch (error) {
        setError(t('error_loading_profile'));
        console.error(t('error_fetching_profile'), error);
        navigate('/login');
      }
    }
    fetchProfile();
  }, [navigate, t]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleDelete = async (itemId) => {
    if (window.confirm(t('confirm_delete_item'))) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/items/${itemId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setItems(items.filter(item => item.id !== itemId));
      } catch (error) {
        setError(t('error_deleting_item'));
        console.error(t('error_deleting_item'), error);
      }
    }
  };

  const handleConfirmRental = async (rentalId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/rentals/${rentalId}/confirm`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setRentals(prevRentals => ({
        ...prevRentals,
        pending: prevRentals.pending.filter(rental => rental.id !== rentalId),
        confirmed: [...prevRentals.confirmed, prevRentals.pending.find(rental => rental.id === rentalId)]
      }));
    } catch (error) {
      setError(t('error_confirming_rental'));
      console.error(t('error_confirming_rental'), error);
    }
  };

  const handleRejectRental = async (rentalId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/rentals/${rentalId}/reject`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setRentals(prevRentals => ({
        ...prevRentals,
        pending: prevRentals.pending.filter(rental => rental.id !== rentalId),
        rejected: [...prevRentals.rejected, prevRentals.pending.find(rental => rental.id === rentalId)]
      }));
    } catch (error) {
      setError(t('error_rejecting_rental'));
      console.error(t('error_rejecting_rental'), error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div className="profile-container">
      {error && <p className="error-message">{error}</p>}
      {user && (
        <>
          <h1>{user.username}'s {t('profile')}</h1>
          <Link to="/profile/chats" className="profile-chats-link">{t('messages')}</Link>
  
          <h2>{t('rentals')}</h2>
          <div className="rentals-section">
            {rentals.pending.length === 0 && rentals.confirmed.length === 0 && rentals.completed.length === 0 && rentals.rejected.length === 0 && (
              <p>{t('no_rentals')}</p>
            )}
  
            {rentals.pending.length > 0 && (
              <div className="rental-cluster">
                <h3>{t('pending')}</h3>
                <ul className="rental-list">
                  {rentals.pending.map(rental => (
                    <li key={rental.id} className="rental-item">
                      <img src={rental.itemImageUrl} alt={rental.itemTitle} className="rental-item-image" />
                      <div className="rental-details">
                        <div className="rental-info">
                          <div className="rental-item-inline">
                            <p className="rental-item-date">
                              <strong>{t('dates')}:</strong> {formatDate(rental.rental_start)} - {formatDate(rental.rental_end)}
                            </p>
                            <span className="rental-icon">
                              {user.id === rental.itemOwnerId ? <FaHome className="rental-icon-img" /> : <FaShoppingBag className="rental-icon-img" />}
                            </span>
                            <p className="rental-user">
                              {user.id === rental.itemOwnerId
                                ? `${t('to')} ${rental.renter_name}`
                                : `${t('from')} ${rental.owner_name}`}
                            </p>
                          </div>
                        </div>
                        {user.id === rental.itemOwnerId && (
                          <div className="rental-actions">
                            <button onClick={() => handleConfirmRental(rental.id)} className="confirm-button">
                              {t('confirm_rental')}
                            </button>
                            <button onClick={() => handleRejectRental(rental.id)} className="reject-button">
                              {t('reject_rental')}
                            </button>
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
  
            {rentals.confirmed.length > 0 && (
              <div className="rental-cluster">
                <h3>{t('confirmed')}</h3>
                <ul className="rental-list">
                  {rentals.confirmed.map(rental => (
                    <li key={rental.id} className="rental-item">
                      <img src={rental.itemImageUrl} alt={rental.itemTitle} className="rental-item-image" />
                      <div className="rental-details">
                        <div className="rental-info">
                          <div className="rental-item-inline">
                            <p className="rental-item-date">
                              <strong>{t('dates')}:</strong> {formatDate(rental.rental_start)} - {formatDate(rental.rental_end)}
                            </p>
                            <span className="rental-icon">
                              {user.id === rental.itemOwnerId ? <FaHome className="rental-icon-img" /> : <FaShoppingBag className="rental-icon-img" />}
                            </span>
                            <p className="rental-user">
                              {user.id === rental.itemOwnerId
                                ? `${t('to')} ${rental.renter_name}`
                                : `${t('from')} ${rental.owner_name}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
  
            {rentals.completed.length > 0 && (
              <div className="rental-cluster">
                <h3>{t('completed')}</h3>
                <ul className="rental-list">
                  {rentals.completed.map(rental => (
                    <li key={rental.id} className="rental-item">
                      <img src={rental.itemImageUrl} alt={rental.itemTitle} className="rental-item-image" />
                      <div className="rental-details">
                        <div className="rental-info">
                          <div className="rental-item-inline">
                            <p className="rental-item-date">
                              <strong>{t('dates')}:</strong> {formatDate(rental.rental_start)} - {formatDate(rental.rental_end)}
                            </p>
                            <span className="rental-icon">
                              {user.id === rental.itemOwnerId ? <FaHome className="rental-icon-img" /> : <FaShoppingBag className="rental-icon-img" />}
                            </span>
                            <p className="rental-user">
                              {user.id === rental.itemOwnerId
                                ? `${t('to')} ${rental.renter_name}`
                                : `${t('from')} ${rental.owner_name}`}
                            </p>
                          </div>
                        </div>
                        {user.id === rental.renter_id && rental.item_reviewed === 'no' && (
                          <Link 
                            to={`/items/${rental.item_id}/review?rentalId=${rental.id}`}
                            className="leave-review-button"
                          >
                            {t('leave_review')}
                          </Link>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
  
            {rentals.rejected.length > 0 && (
              <div className="rental-cluster">
                <h3>{t('rejected')}</h3>
                <ul className="rental-list">
                  {rentals.rejected.map(rental => (
                    <li key={rental.id} className="rental-item">
                      <img src={rental.itemImageUrl} alt={rental.itemTitle} className="rental-item-image" />
                      <div className="rental-details">
                        <div className="rental-info">
                          <div className="rental-item-inline">
                            <p className="rental-item-date">
                              <strong>{t('dates')}:</strong> {formatDate(rental.rental_start)} - {formatDate(rental.rental_end)}
                            </p>
                            <span className="rental-icon">
                              {user.id === rental.itemOwnerId ? <FaHome className="rental-icon-img" /> : <FaShoppingBag className="rental-icon-img" />}
                            </span>
                            <p className="rental-user">
                              {user.id === rental.itemOwnerId
                                ? `${t('to')} ${rental.renter_name}`
                                : `${t('from')} ${rental.owner_name}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
  
          <h2>{t('items_posted')}</h2>
          <Link to="/add-item" className="add-item-link">{t('add_item')}</Link> {/* Moved here */}
  
          {items.length === 0 && (
            <p>{t('no_items_posted')}</p>
          )}
          <ul className="item-list">
            {items.map((item) => (
              <li key={item.id} className="item">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <p>{t('Price')}: {item.price} €/day</p>
                {item.imageurl && <img src={item.imageurl} alt={item.title} className="item-image" />}
                <p>{t('category')}: {item.category}</p>
                <div className="button-container">
                  <button onClick={() => navigate(`/edit-item/${item.id}`)} className="edit-button">{t('edit')}</button>
                  <button onClick={() => handleDelete(item.id)} className="delete-button">{t('delete')}</button>
                </div>
              </li>
            ))}
          </ul>
  
          <button onClick={handleLogout} className="logout-link">{t('logout')}</button>
        </>
      )}
    </div>
  );    
};

export default Profile;
