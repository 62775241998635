import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './Homepage.css';

function Homepage() {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const itemsListRef = useRef(null);

  useEffect(() => {
    const fetchLocationAndItems = async () => {
      try {
        const geoResponse = await axios.get(`https://ipinfo.io?token=${process.env.REACT_APP_IPINFO_TOKEN}`);
        const { loc } = geoResponse.data;
        const [latitude, longitude] = loc.split(',');

        const itemsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/items/nearby`, {
          params: {
            lat: latitude,
            lon: longitude,
            radius: 100,
          },
        });

        setItems(itemsResponse.data);
      } catch (error) {
        console.error('Error fetching geolocation or items:', error);
      }
    };

    fetchLocationAndItems();
  }, []);

  const scrollItems = (direction) => {
    const itemsList = itemsListRef.current;
    const scrollAmount = 200;

    if (direction === 'left') {
      itemsList.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else {
      itemsList.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="homepage">
      <header className="hero-section">
        <h1>{t('welcomeTitle')}</h1>
        <p>{t('welcomeSubtitle')}</p>
        <div className="button-container">
          <Link to="/items" className="cta-button">
            {t('browseItems')}
          </Link>
          <Link to="/login" className="cta-button login-button">
            {t('login')}
          </Link>
        </div>
      </header>

      {/* Scrollable List of Items */}
      {items.length > 0 && (
        <section className="items-nearby">
          <h2>{t('itemsNearby')}</h2>
          <div className="items-list-container">
            <button className="scroll-arrow left-arrow" onClick={() => scrollItems('left')}>‹</button>
            <div className="items-list" ref={itemsListRef}>
              {items.map(item => (
                <div key={item.id} className="item-card">
                  <Link to={`/items/${item.id}`}>
                    <img src={item.imageurl} alt={item.title} className="item-image" />
                    <h3>{item.title}</h3>
                  </Link>
                  <p>{item.description}</p>
                  <p>{item.price} €/day</p>
                </div>
              ))}
            </div>
            <button className="scroll-arrow right-arrow" onClick={() => scrollItems('right')}>›</button>
          </div>
        </section>
      )}

      <section className="features">
        <div className="feature">
          <h2>{t('findWhatYouNeedTitle')}</h2>
          <p>{t('findWhatYouNeedDescription')}</p>
        </div>
        <div className="feature">
          <h2>{t('easyBookingTitle')}</h2>
          <p>{t('easyBookingDescription')}</p>
        </div>
        <div className="feature">
          <h2>{t('manageYourItemsTitle')}</h2>
          <p>{t('manageYourItemsDescription')}</p>
        </div>
      </section>
    </div>
  );
}

export default Homepage;
