import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom'; // useNavigate for redirection
import { FaStar } from 'react-icons/fa'; // Import star icons
import { jwtDecode } from 'jwt-decode'; // Corrected import for jwt-decode
import './ReviewForm.css'; // Add custom styles

function ReviewForm() {
  const { itemId } = useParams(); // Get the itemId from the URL
  const location = useLocation();
  const navigate = useNavigate(); // For redirection
  const rentalId = new URLSearchParams(location.search).get('rentalId'); // Get rentalId from the query string
  const [overallRating, setOverallRating] = useState(0);
  const [functionalityRating, setFunctionalityRating] = useState(0);
  const [conditionRating, setConditionRating] = useState(0);
  const [valueForMoneyRating, setValueForMoneyRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [error, setError] = useState(null);
  const [rental, setRental] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null); // Store the current user's ID from the token
  const [showPopup, setShowPopup] = useState(false); // Control popup visibility

  // Fetch rental details to ensure it's valid for review
  useEffect(() => {
    const fetchRentalDetails = async () => {
      try {
        // Decode the JWT token to get the current userId
        const token = localStorage.getItem('token');
        if (token) {
          const decoded = jwtDecode(token);
          setUserId(decoded.id); // Set the userId from the token
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/rentals/${rentalId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRental(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch rental details');
        setLoading(false);
      }
    };

    fetchRentalDetails();
  }, [rentalId]);

  const handleStarClick = (setRatingFunction, rating) => {
    setRatingFunction(rating); // Update the rating when a star is clicked
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure the rental exists, hasn't been reviewed, and the current user is the renter
    if (!rental) {
      setError('Invalid rental.');
      return;
    }

    if (rental.item_reviewed === 'yes') {
      setError('This item has already been reviewed.');
      return;
    }

    if (rental.renter_id !== userId) {
      setError('You are not authorized to review this item.');
      return;
    }

    if (overallRating === 0 || functionalityRating === 0 || conditionRating === 0 || valueForMoneyRating === 0) {
      setError('Please provide ratings for all categories');
      return;
    }

    const reviewData = {
      overallRating,
      functionalityRating,
      conditionRating,
      valueForMoneyRating,
      reviewText,
    };

    try {
      // Submit review
      await axios.post(`${process.env.REACT_APP_API_URL}/items/${itemId}/review`, reviewData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Update the rental's item_reviewed status to "yes"
      await axios.post(`${process.env.REACT_APP_API_URL}/rentals/${rentalId}/mark-reviewed`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Show popup instead of alert
      setShowPopup(true);
    } catch (err) {
      setError('Failed to submit review or mark rental as reviewed');
      console.error('Error:', err);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    navigate('/profile'); // Redirect to profile page once popup is closed
  };

  if (loading) return <p>Loading rental details...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <>
      <form onSubmit={handleSubmit} className="review-form">
        <h3>Leave a Review</h3>
        
        {/* Overall Rating */}
        <label>
          Overall Rating:
          <div className="star-rating">
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              return (
                <FaStar
                  key={ratingValue}
                  className={`star ${ratingValue <= overallRating ? 'filled' : ''}`}
                  onClick={() => handleStarClick(setOverallRating, ratingValue)}
                />
              );
            })}
          </div>
        </label>

        {/* Functionality Rating */}
        <label>
          Functionality:
          <div className="star-rating">
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              return (
                <FaStar
                  key={ratingValue}
                  className={`star ${ratingValue <= functionalityRating ? 'filled' : ''}`}
                  onClick={() => handleStarClick(setFunctionalityRating, ratingValue)}
                />
              );
            })}
          </div>
        </label>

        {/* Item Condition Rating */}
        <label>
          Item Condition:
          <div className="star-rating">
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              return (
                <FaStar
                  key={ratingValue}
                  className={`star ${ratingValue <= conditionRating ? 'filled' : ''}`}
                  onClick={() => handleStarClick(setConditionRating, ratingValue)}
                />
              );
            })}
          </div>
        </label>

        {/* Value for Money Rating */}
        <label>
          Value for Money:
          <div className="star-rating">
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              return (
                <FaStar
                  key={ratingValue}
                  className={`star ${ratingValue <= valueForMoneyRating ? 'filled' : ''}`}
                  onClick={() => handleStarClick(setValueForMoneyRating, ratingValue)}
                />
              );
            })}
          </div>
        </label>

        {/* Review Text */}
        <label>
          Review:
          <textarea
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
          />
        </label>
        <button type="submit">Submit Review</button>
      </form>

      {/* Popup Modal */}
      {showPopup && (
        <div className="popup-modal">
          <div className="popup-content">
            <h3>Thank you for leaving your review!</h3>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default ReviewForm;
