import React, { useEffect, useState } from 'react'; 
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ItemDetail.css';

function ItemDetail() {
  const { id } = useParams();
  const { t } = useTranslation();  // Use the translation hook
  const [item, setItem] = useState(null);
  const [message, setMessage] = useState('');
  const [rentalStart, setRentalStart] = useState('');
  const [rentalEnd, setRentalEnd] = useState('');
  const [error, setError] = useState(null);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // Fetch item details
    async function fetchItemDetail() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/items/${id}`);
        setItem(response.data);
      } catch (error) {
        setError(t('error_loading_item'));
        console.error(t('error_fetching_item'), error);
      }
    }

    // Fetch reviews
    async function fetchReviews() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/items/${id}/reviews`);
        setReviews(response.data);
      } catch (error) {
        console.error(t('error_fetching_reviews'), error);
        setError(t('error_loading_reviews'));
      }
    }

    fetchItemDetail();
    fetchReviews();
  }, [id, t]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!item || !item.userId) {
      setError(t('item_or_user_not_found'));
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      setError(t('user_not_authenticated'));
      return;
    }

    try {
      // Create a conversation if not exists
      const conversationResponse = await axios.post(`${process.env.REACT_APP_API_URL}/conversations`, {
        recipientId: item.userId,
        itemId: item.id,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const conversationId = conversationResponse.data.id;

      // Send the message
      await axios.post(`${process.env.REACT_APP_API_URL}/messages`, {
        conversationId,
        message,
        receiverId: item.userId,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // If rental dates are provided, send a rental request
      if (rentalStart && rentalEnd) {
        await axios.post(`${process.env.REACT_APP_API_URL}/items/${id}/rent`, {
          rentalStart,
          rentalEnd,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        alert(t('message_and_rental_sent'));
      } else {
        alert(t('message_sent'));
      }

      setMessage('');
      setRentalStart('');
      setRentalEnd('');
    } catch (error) {
      setError(t('error_sending_message_or_rental'));
      console.error(t('error_sending'), error);
    }
  };

  // Helper function to render stars with support for half-stars
  const renderStars = (rating) => {
    const roundedRating = Math.round(rating * 2) / 2; // Round to nearest half
    return (
      <div className="star-rating-inline">
        {Array(5).fill(0).map((_, index) => {
          const starValue = index + 1;
          return (
            <span key={index} className="star">
              {starValue <= roundedRating ? (
                <span className="filled">&#9733;</span> // Full star
              ) : starValue - 0.5 === roundedRating ? (
                <span className="half-filled">&#9733;</span> // Half star
              ) : (
                <span className="empty">&#9733;</span> // Empty star
              )}
            </span>
          );
        })}
      </div>
    );
  };

  if (error) return <p className="error-message">{error}</p>;
  if (!item) return <div>{t('loading')}</div>;

  return (
    <div className="item-detail-container">
      <h1>{item.title}</h1>
      {item.imageurl && (
        <>
          <img src={item.imageurl} alt={item.title} className="item-image" />
          {item.average_rating != null && (
            <div className="average-rating">
              <span className="rating-number">
                {parseFloat(item.average_rating).toFixed(1)} ({item.review_count})
              </span>
              {renderStars(item.average_rating)}
            </div>
          )}
        </>
      )}
      <p>{item.description}</p>
      <p><strong>{t('Price')}:</strong> {item.price} €/day</p>
      <p><strong>{t('category')}:</strong> {item.category}</p>
      <p><strong>{t('location')}:</strong> {item.location}</p>
      <p><strong>{t('posted_by')}:</strong> {item.username}</p>

      <div className="message-form-container">
        <div className="message-form">
          <h2>{t('send_message')}</h2>
          <form onSubmit={handleSendMessage}>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t('write_message')}
              required
            />
            <h3>{t('add_rental_request')}</h3>
            <div className="rental-date-container">
              <div className="rental-date-row">
                <label>{t('rental_start_date')}:</label>
                <input
                  type="date"
                  value={rentalStart}
                  onChange={(e) => setRentalStart(e.target.value)}
                />
              </div>
              <div className="rental-date-row">
                <label>{t('rental_end_date')}:</label>
                <input
                  type="date"
                  value={rentalEnd}
                  onChange={(e) => setRentalEnd(e.target.value)}
                />
              </div>
            </div>
            <button type="submit">
              {rentalStart && rentalEnd ? t('send_request') : t('send_message')}
            </button>
          </form>
        </div>
      </div>

      <div className="reviews-section">
        <h2>{t('reviews_ratings')}</h2>
        {reviews.length > 0 ? (
          reviews.map((review) => (
            <div key={review.id} className="review">
              <p><strong>{review.username}</strong></p>
              {renderStars(review.overall_rating)}
              {review.review_text && <p>{review.review_text}</p>}
              <hr />
            </div>
          ))
        ) : (
          <p>{t('no_reviews')}</p>
        )}
      </div>
    </div>
  );
}

export default ItemDetail;
