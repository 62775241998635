import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ConversationDetail.css';

function ConversationDetail() {
  const { conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [yourUserId, setYourUserId] = useState(null);
  const [receiverId, setReceiverId] = useState(null);
  const [rentalStart, setRentalStart] = useState('');
  const [rentalEnd, setRentalEnd] = useState('');
  const [itemId, setItemId] = useState(null);  // Store item ID for the rental request
  const [isFirstMessageSender, setIsFirstMessageSender] = useState(false);  // Track if the user is the sender of the first message
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchUserId() {
      const token = localStorage.getItem('token');
      if (!token) {
        setError(t('User not authenticated'));
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setYourUserId(response.data.user.id);
      } catch (error) {
        setError(t('Failed to fetch user profile'));
        console.error('Error fetching user profile:', error);
      }
    }

    fetchUserId();
  }, [t]);

  useEffect(() => {
    if (yourUserId === null) return;

    async function fetchConversationDetailsAndMessages() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/conversations/${conversationId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const { conversation, itemId, messages } = response.data;

        setMessages(messages);
        setItemId(itemId);  // Store the item ID for later use

        // Check if the current user is the sender of the first message
        if (messages.length > 0 && messages[0].sender_id === yourUserId) {
          setIsFirstMessageSender(true);
        }

        const messageIds = messages
          .filter(msg => msg.sender_id !== yourUserId)
          .map(msg => msg.id);

        if (messageIds.length > 0) {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/messages/read`, {
            messageIds
          }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          });

          // Notify the Navbar to update unread message count
          localStorage.setItem('unreadMessages', res.data.count);
          window.dispatchEvent(new Event('unreadMessagesUpdated'));
        }

        if (receiverId === null && messages.length > 0) {
          const firstMessage = messages[0];
          setReceiverId(firstMessage.sender_id === yourUserId ? messages.find(msg => msg.sender_id !== yourUserId)?.sender_id : firstMessage.sender_id);
        }
      } catch (error) {
        setError(t('Failed to load conversation and messages'));
        console.error('Error fetching conversation and messages:', error);
      }
    }

    fetchConversationDetailsAndMessages();
  }, [conversationId, yourUserId, t, receiverId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (receiverId === null) {
      setError(t('Receiver not found'));
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/messages`, {
        conversationId,
        message,
        receiverId
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      const newMessage = { id: response.data.id, message, sender_id: yourUserId, timestamp: new Date().toISOString() };

      // Append the new message to the sorted list
      const updatedMessages = [...messages, newMessage].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      setMessages(updatedMessages);
      setMessage('');
    } catch (error) {
      setError(t('Failed to send message'));
      console.error('Error sending message:', error);
    }
  };

  const handleSendRentalRequest = async (e) => {
    e.preventDefault();

    if (!rentalStart || !rentalEnd) {
      setError(t('Please provide both start and end dates for the rental request'));
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/items/${itemId}/rent`, {  // Use the itemId from the conversation
        rentalStart,
        rentalEnd,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      alert(t('Rental request sent successfully'));
      setRentalStart('');
      setRentalEnd('');
    } catch (error) {
      setError(t('Failed to send rental request'));
      console.error('Error sending rental request:', error);
    }
  };

  return (
    <div className="conversation-detail-container">
      <Link to="/profile/chats" className="back-link">{t('Back to Chats')}</Link> {/* Move link to top */}
      <h1>{t('Conversation Details')}</h1>
      {error && <p className="error-message">{error}</p>}
      
      <div className="messages">
        {messages.map((msg) => (
          <div key={msg.id} className={`message ${msg.sender_id === yourUserId ? 'sent' : 'received'}`}>
            <p>{msg.message}</p>
          </div>
        ))}
      </div>
      <div className="message-form">
        <form onSubmit={handleSendMessage}>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={t('Write your message here')}
            required
          />
          <button type="submit">{t('Send')}</button>
        </form>
      </div>

      {/* Rental Request Section (only visible if the current user is the sender of the first message) */}
      {isFirstMessageSender && (
        <div className="rental-request-form">
          <h3>{t('Send a Rental Request')}</h3>
          <form onSubmit={handleSendRentalRequest}>
            <div className="rental-date-row-inline">
              <div className="rental-date-col">
                <label>{t('Rental Start Date')}:</label>
                <input
                  type="date"
                  value={rentalStart}
                  onChange={(e) => setRentalStart(e.target.value)}
                  required
                />
              </div>
              <div className="rental-date-col">
                <label>{t('Rental End Date')}:</label>
                <input
                  type="date"
                  value={rentalEnd}
                  onChange={(e) => setRentalEnd(e.target.value)}
                  required
                />
              </div>
            </div>
            <button type="submit">{t('Send Rental Request')}</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default ConversationDetail;
