import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; // Import the Footer component
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ItemList from './components/Items/ItemList';
import ItemDetail from './components/Items/ItemDetail';
import Profile from './components/Profile/Profile';
import Homepage from './components/Homepage';
import AddItem from './components/Items/AddItem';
import ProfileChats from './components/Profile/ProfileChats';
import ConversationDetail from './components/ConversationDetail/ConversationDetail';
import EditItem from './components/Items/EditItem'; // Import the new EditItem component
import TermsAndConditionsLoader from './components/TermsAndConditions/TermsAndConditionsLoader';
import PrivacyPolicyLoader from './components/TermsAndConditions/PrivacyPolicyLoader';
import ReviewForm from './components/Reviews/ReviewForm'; // Import the ReviewForm component
import { GoogleMapsProvider } from './contexts/GoogleMapsContext'; // Import the provider
import './i18n/i18n'; // Import the i18n configuration

function App() {
  return (
    <GoogleMapsProvider>
      <Router>
        <div className="app-container">
          <Navbar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/items" element={<ItemList />} />
            <Route path="/items/:id" element={<ItemDetail />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/chats" element={<ProfileChats />} />
            <Route path="/conversations/:conversationId" element={<ConversationDetail />} />
            <Route path="/add-item" element={<AddItem />} />
            <Route path="/edit-item/:itemId" element={<EditItem />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditionsLoader />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyLoader />} />
            <Route path="/items/:itemId/review" element={<ReviewForm />} /> {/* Add ReviewForm route */}
          </Routes>
          <Footer /> {/* Add the Footer component here */}
        </div>
      </Router>
    </GoogleMapsProvider>
  );
}

export default App;
